import React, { useEffect, useState } from 'react';
import OurProcess from '../common/OurProcess';
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button } from '@mui/material';
import { FaStar, FaStarHalf } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from 'react-helmet';
import Rating from '@mui/material/Rating';

import Aos from 'aos';
import { motion } from 'framer-motion';
import { fixedContainer, btnVariants, textVariants } from '../common/motion/motion';


const Services = () => {

  const { id } = useParams();

  const myFun = (e) => {
    console.log(e.target)
  }

  const [subServices, setSubServices] = useState([]);
  const [recentWork, setRecentWork] = useState([]);
  const [bannerContent, setBannerContent] = useState([]);
  const [myspinner, setMyspinner] = useState(false);
  const [seoDes, setSeoDes] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [robot, setRobot] = useState('');
  const [seoScript, setSeoScript] = useState('');
  const [seoKeyword, setSeoKeyword] = useState('');
  const [canonical, setCanonical] = useState('');



  const banner = bannerContent?.map(banner => banner.banner_image);
  const bannerTest = bannerContent?.map(banner => banner.banner_text);


  const getAllData = () => {

    axios.get(`/getSingleService/${id}`
    ).then((response) => {
      setBannerContent(response.data.data);
      console.log(response.data.data);

    });

    axios.get(`/persubservice/${id}`
    ).then((response) => {
      setSubServices(response.data.data);
      console.log(response.data.data);

    });


    axios.get(`/getRecentwork/${id}`
    ).then((response) => {
      if (response.status === 200) {
        setRecentWork(response.data.data);
        console.log(response.data.data);
        setMyspinner(false)
      }
    })
  }


  const handleSEO = () => {
    bannerContent?.map((seo) => {

      setRobot(seo.robot);
      setSeoTitle(seo.seo_title);
      setSeoKeyword(seo.seo_keyword)
      setSeoDes(seo.seo_description);
      setCanonical(seo.canonical)
      setSeoScript(seo.script);
    })
  }

  useEffect(() => {
    handleSEO();
  }, [bannerContent]);


  useEffect(() => {
    window.scrollTo(0, 0);
    getAllData();
    setMyspinner(true);
    Aos.init({
      duration: 1000,
    })
  }, [id])

  console.log(window.location)

  return (

    <>

      <Helmet>
        <link rel="canonical" href={canonical} />
        <title>{seoTitle}</title>
        <meta name="description" content={seoDes} />
        <meta name="keywords" content={seoKeyword} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:site_name" content="hweindia" />
        <meta property="og:url" content={canonical} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDes} />
        <meta name="robots" content={robot} />
        <script>{seoScript}</script>

      </Helmet>

      <div className={myspinner ? 'spinner_d' : 'd-none'}>
        <Spinner animation="border" className='mySpinner'/>
      </div>

      <div className='fix_width' style={myspinner ? { opacity: '0.5' } : { opacity: '1' }}>
        <div>
          <section>
            <div className='banner container-fluid'>
              <div className='row' style={{ padding: "3rem 0rem" }}>
                <div className='content_div col-lg-6 col-md-12 col-sm-12 col-12'>
                  <motion.Button
                    variants={textVariants('up', 0.1)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    className='strar-btn mb-3' >
                    <a href="https://goo.gl/maps/zLHxYWCKEEj8oM8s7" target="_blank">
                      <div className='starDiv'>
                        <span>4.8</span>
                        <Rating
                          name="hover-feedback"
                          defaultValue={4.5}
                          precision={0.5}
                          readOnly
                        // emptyIcon={<FaStar style={{ opacity: 0.55 }} fontSize="inherit" />}
                        />
                      </div>
                      from 200 Client's reviews</a></motion.Button>
                  <motion.h3
                    variants={textVariants('up', 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                  >{bannerTest}</motion.h3>
                  <motion.Button
                    variants={textVariants('up', 0.3)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    className='btn appointment_btn_yellow' style={{ padding: '10px 20px', borderRadius: '0' }}><Link to='/contactus'>
                      {/* <img style={{ width: '25px' }} src={bookAppoinment2} />  */}
                      Book Appointment</Link></motion.Button>
                </div>
                <div className='serviceImgDiv col-lg-6 col-md-12 col-sm-12 col-12'>
                  <motion.img
                    variants={textVariants('down', 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    src={`/assets/service/${banner}`}
                  />
                 
                </div>
              </div>
            </div>
          </section>
         
        </div>

        <section className='service-s' style={{ position: "relative", marginTop: "3rem" }}>
          <motion.div
            variants={fixedContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            style={{ backgroundColor: "#146FB7", width: "80%", height: "300px" }}></motion.div>
          <div class="our_services" style={{ marginTop: "-252px", minHeight: "400px" }}>
            <motion.center
              variants={fixedContainer}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              className="mb-4">
              <motion.h5
                variants={textVariants('up', 0.1)}
                style={{ justifyContent: 'center' }}>Our Service</motion.h5>
              <motion.h3
                variants={textVariants('up', 0.2)}
                className='title'>Services We Offer</motion.h3>
            </motion.center>
            <div class="container-fluid">
              <div class="grid"

              >
                {subServices?.map((subservice, index) => {
                  return (
                    <motion.div class="item item--medium"
                      variants={textVariants('left', index / 10)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}

                    >
                      <Link
                        // variants={textVariants('left', index / 10)}
                        to={`/services/${subservice.name.replace(/\s+/g, '-').toLowerCase()}/${id}/${subservice.subservice_comb_id}`} class="item item--medium h-100" style={{ backgroundImage: `url(/assets/subservice/${subservice.image})` }} >
                        <div class="item__details">
                          {subservice.name}
                        </div>
                      </Link>
                    </motion.div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>

        <OurProcess />

        <section style={{ position: 'relative' }} className='our_blog-s'>
          <div style={{ backgroundColor: "#DBA11C", width: "80%", height: "300px", margin: "0px 0px 0px auto" }}>
          </div>
          <div className='our_blog' style={{ marginTop: "-252px" }}>
            <motion.center
              variants={fixedContainer}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              className="mb-4">
              <motion.h5
                variants={textVariants('up', 0.1)}
                style={{ justifyContent: 'center' }}>Our Work</motion.h5>
              <motion.h3
                variants={textVariants('up', 0.2)}
                className='title'>Recent Projects</motion.h3>
            </motion.center>

            <div className='container-fluid'>
              <Swiper
                slidesPerView={4}
                spaceBetween={36}
                slidesPerGroup={2}
                loop={false}
                loopFillGroupWithBlank={true}
               
                modules={[Pagination]}
               
                pagination={{
                  dynamicBullets: true,
                }}

                className="workSwiper2"

                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                  },

                  640: {

                    slidesPerView: 1,
                  },

                  768: {

                    slidesPerView: 2,
                  },
                  992: {
                    slidesPerView: 2,
                  },

                }}
              >


                {recentWork?.map((work, index) => {
                  return (
                    <div>
                      <SwiperSlide>
                        <motion.div
                          variants={fixedContainer}
                          initial="hidden"
                          whileInView="show"
                          viewport={{ once: true, amount: 0.1 }}
                          className="mb-4">
                          <motion.img
                            variants={textVariants('left', 0.1)}
                            src={`/assets/recentwork/${work.before_image}`} width='100%' height='250px' />
                        </motion.div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <motion.div
                          variants={fixedContainer}
                          initial="hidden"
                          whileInView="show"
                          viewport={{ once: true, amount: 0.1 }}
                          className="mb-4">
                          <motion.img
                            variants={textVariants('left', 0.2)}
                            src={`/assets/recentwork/${work.after_image}`} width='100%' height='250px' />
                        </motion.div>
                      </SwiperSlide>
                    </div>
                  )
                })}

              </Swiper>
            </div>

          </div>
        </section>
      </div>
    </>
  )
}

export default Services